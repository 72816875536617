import {IonButton, IonContent, IonPage} from '@ionic/react';
import './Info.css';
import React from "react";
import { startHunt } from '../features/hunt';
import {useHistory} from "react-router";
import {useAppDispatch} from "../store";

const Info = () => {
    const dispatch = useAppDispatch()
    const history = useHistory();
    return (
        <IonPage title={""}>
          <IonContent fullscreen>
              <h1>Qr Hunt</h1>
              <p>Qr Hunt is a game...</p>
              <IonButton
                  expand="full"
                  onClick={()=>{
                      dispatch(startHunt(""))
                      history.push('/Info');
                  }}
              >Start Hunt</IonButton>
          </IonContent>
        </IonPage>
    );
};

export default Info;
