import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  setupIonicReact
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import { ToastContainer } from 'react-toastify';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import 'react-toastify/dist/ReactToastify.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Start from "./pages/Start";
import Hunting from './Hunting';
import React, {useState} from "react";
import {Provider, shallowEqual, useSelector} from "react-redux";

setupIonicReact();

interface HuntingDeciderProps {
  isHunting: boolean
}

function HuntingDecider(props: HuntingDeciderProps) {
    console.log("Hunting !!!!",props.isHunting)
  if (props.isHunting) {
    return (<Hunting/>)
  } else {
    return (<Redirect to="/Start"/>)
  }
}

const App = () => {
    const onHunt = useSelector((state:any={}) => {
            return state.hunt.onHunt
    }, shallowEqual)

    return (
    <IonApp>
            <IonReactRouter>
              <Route exact path="/Start">
                <Start />
              </Route>
              <Route path="/">
                <HuntingDecider isHunting = {onHunt}/>
              </Route>
              <ToastContainer />
            </IonReactRouter>
    </IonApp>
    );
}

export default App;
