import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Scan.css';
import QrCodeContainer from "../components/QrCodeContainer";

import { nextPosition } from '../features/hunt';
import {toast} from "react-toastify";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {useHistory} from "react-router";
import {Html5QrcodeResult} from "html5-qrcode/esm/core";

const errorId = "error";
const successId = "success";

const Scan = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const idToFind = useSelector((state:any={}) => {
            console.log("Scan State", state)
            return state.hunt.idToFind
        })
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Scan</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Scan</IonTitle>
          </IonToolbar>
        </IonHeader>
        <QrCodeContainer callback={(decodedText: string) => {
            const processed = decodedText.split("=")[1]
            console.log(processed, idToFind)
            if(processed !== idToFind){
                toast("The qrcode was wrong", {
                    toastId: errorId
                })
            }else {
                toast("The qrcode was correct", {
                    toastId: successId
                })
                dispatch(nextPosition(""))
                history.push('/Hunt');
            }
        }} />
      </IonContent>
    </IonPage>
  );
};

export default Scan;
