import './QrCodeContainer.css';
import React, {useEffect, useState} from 'react'
import Html5QrcodePlugin from "../plugin/Html5QrcodePlugin";
interface ContainerProps {
    callback: (decodedText: string) => void;
}
const QrCodeContainer: React.FC<ContainerProps> = ({ callback }) => {
  return (
    <div className="container">
        <Html5QrcodePlugin
            fps={10}
            qrbox={350}
            disableFlip={false}
            qrCodeSuccessCallback={callback}
        />
    </div>
  );
};
export default QrCodeContainer;
