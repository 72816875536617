import {Html5Qrcode, Html5QrcodeScanner, Html5QrcodeSupportedFormats} from "html5-qrcode";
import React, {useEffect, useState} from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {};
    config.fps = props.fps || 10;
    config.qrbox = props.qrbox || { width: 250, height: 250 };
    config.formatsToSupport = [ Html5QrcodeSupportedFormats.QR_CODE ];
    config.aspectRatio = props.aspectRatio || 1.0;
    config.rememberLastUsedCamera = true;
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    return config;
};

const None = "None"
const Scan = "Scan"
const File = "File"

const Html5QrcodePlugin = (props) => {
    const [mode, setMode] = useState(None);

    useEffect(() => {
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        switch (mode) {
            case None:
            default:
                break;
            case Scan:
                const scanner = new Html5Qrcode(qrcodeRegionId, verbose);
                scanner.start(
                    { facingMode: "environment" },
                    config,
                    props.qrCodeSuccessCallback,
                    undefined
                ).catch(error => {
                    console.error("Failed to start html5Qrcode. ", error);
                });

                // cleanup function when component will unmount
                return () => {
                    scanner.stop().catch(error => {
                        console.error("Failed to clear html5Qrcode. ", error);
                    });
                };
            case File:
                const fileScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
                fileScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback)

                // cleanup function when component will unmount
                return () => {
                    fileScanner.clear().catch(error => {
                        console.error("Failed to clear html5QrcodeScanner. ", error);
                    });
                };
        }
    },[mode]);

    useEffect(() => {
        // when component mounts
        Html5Qrcode.getCameras().then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            if (devices && devices.length) {
                setMode(Scan);
            }else{
                setMode(File);
            }
        }).catch(err => {
            setMode(File);
        });
    }, [props]);


    return (
        <div id={qrcodeRegionId} />
    );
};

export default Html5QrcodePlugin;