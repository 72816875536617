import { createSlice } from '@reduxjs/toolkit'
import {useSelector} from "react-redux";

type Points = {
    [key: string]: [number, number]
}

const huntPointMap : Points = {
    "00000000-0000-0000-0000-000000000001": [ 50.231811586663994, 10.863597342364182 ],
    "00000000-0000-0000-0000-000000000002": [ 50.229007718528266, 10.860523610578495 ],
    "00000000-0000-0000-0000-000000000003": [ 50.23070075182865, 10.866216050330594 ],
    "00000000-0000-0000-0000-000000000004": [ 50.23317187849971, 10.87029269152337 ]
}

export const huntSlice = createSlice({
    name: 'hunt',
    initialState: {
        onHunt: false,
        onSuccess: false,
        path: [""],
        idToFind: "",
        pathPosition: 0,
        location: [50.0,10.0]
    },
    reducers: {
        startHunt:(state,action)=>{
            state.onHunt=true
            state.onSuccess=false
            state.path = [
                "00000000-0000-0000-0000-000000000002",
                "00000000-0000-0000-0000-000000000003",
                "00000000-0000-0000-0000-000000000004",
                "00000000-0000-0000-0000-000000000001",
            ]
            state.idToFind = state.path[0]
            state.pathPosition = 0
            state.location = huntPointMap[state.path[state.pathPosition]]
        },
        nextPosition:(state, action)=>{
            if(state.pathPosition < state.path.length - 1){
                state.pathPosition += 1
                state.idToFind = state.path[state.pathPosition]
                state.location = huntPointMap[state.path[state.pathPosition]]
            } else {
                state.onSuccess = true
                state.pathPosition += 1
                state.idToFind = "unknown"
            }
        },
        abortHunt:(state,action)=>{
            state.onHunt=false
            state.path = []
            state.pathPosition = 0
            state.location = [50.0,10.0]
        }
    },
})

export const {
    startHunt,
    nextPosition,
    abortHunt
} = huntSlice.actions

export default huntSlice.reducer