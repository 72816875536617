import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Hunt.css';
import React, {useState} from "react";
import ReactLeafletContainer from "../components/ReactLeafletContainer";
import {shallowEqual, useSelector} from "react-redux";

const Hunt = () => {
  const location = useSelector((state:any={}) => state.hunt.location, shallowEqual)
    return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Hunt</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Hunt</IonTitle>
          </IonToolbar>
        </IonHeader>
          <ReactLeafletContainer target={location}></ReactLeafletContainer>
      </IonContent>
    </IonPage>
  );
};
export default Hunt;
