import './ReactLeafletContainer.css';
import 'leaflet/dist/leaflet.css';
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import React from "react";
import {MapContainer, CircleMarker, Marker, Polyline, Popup, TileLayer, Tooltip, useMap} from "react-leaflet";
import { useGeolocated } from "react-geolocated";
import L from 'leaflet';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
});

interface ContainerProps {
    target: number[]
}

const ComponentResize = () => {
    const map = useMap()

    setTimeout(() => {
        map.invalidateSize()
    }, 0)

    return null
}

const ReactLeafletContainer: React.FC<ContainerProps> = ({ target}) => {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
            watchPosition: true,
        });
     if(isGeolocationAvailable
        && isGeolocationEnabled
        && typeof coords?.longitude === "number" && typeof coords?.latitude === "number") {
        let position = [coords?.latitude, coords?.longitude]
        return (
            <MapContainer
                style={{
                    height: "100%",
                    width: "100%",
                }}
                /* @ts-ignore */
                center={position} zoom={13} scrollWheelZoom={false}>
                <ComponentResize/>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                <CircleMarker
                    /* @ts-ignore */
                    center={position}
                    pathOptions={{
                        color: 'blue',
                        fill: true
                    }}
                    radius={10}>
                    <Tooltip>Your position</Tooltip>
                </CircleMarker>
                {/* @ts-ignore */}
                <Marker position={[target[0], target[1]]}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
                <Polyline positions={
                    [
                        [position[0], position[1] ],
                        [target[0], target[1]]
                    ]} pathOptions={{
                    color: 'blue'
                }}
                ></Polyline>
            </MapContainer>
        );
    } else{
        return(
            <MapContainer
                style={{
                    height: "100%",
                    width: "100%",
                }}
                /* @ts-ignore */
                center={[target[0], target[1]]} zoom={13} scrollWheelZoom={false}>
                <ComponentResize/>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                <Marker position={[target[0], target[1]]}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        )
    }
};

export default ReactLeafletContainer;
