import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {  information, locate, barcode } from 'ionicons/icons';
import { ToastContainer } from 'react-toastify';
import Info from './pages/Info';
import Hunt from './pages/Hunt';
import Scan from './pages/Scan';

const Hunting = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/Info">
            <Info />
          </Route>
          <Route exact path="/Hunt">
            <Hunt />
          </Route>
          <Route path="/Scan">
            <Scan />
          </Route>
          <Route exact path="/">
            <Redirect to="/Info" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="Info" href="/Info">
            <IonIcon icon={information} />
            <IonLabel>Info</IonLabel>
          </IonTabButton>
          <IonTabButton tab="Hunt" href="/Hunt">
            <IonIcon icon={locate} />
            <IonLabel>Hunt</IonLabel>
          </IonTabButton>
          <IonTabButton tab="Scan" href="/Scan">
            <IonIcon icon={barcode} />
            <IonLabel>Scan</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <ToastContainer />
    </IonReactRouter>
  </IonApp>
);

export default Hunting;
