import {IonButton, IonContent, IonHeader, IonPage, IonProgressBar, IonTitle, IonToolbar} from '@ionic/react';
import './Info.css';
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { abortHunt } from '../features/hunt';

const Info = () => {
    const hunt = useSelector((state:any={}) => state.hunt)

    const dispatch = useDispatch()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Info</IonTitle>
          </IonToolbar>
        </IonHeader>
        <h3>Progress</h3>
        <IonProgressBar value={hunt.path.length !== 0 ? hunt.pathPosition/hunt.path.length : 0}></IonProgressBar>
        <IonButton expand="full" onClick={()=>dispatch(abortHunt(""))}>Stop Hunt</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Info;
